import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { ListItem } from '../components/CustomStyles/Typography';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	header: {
		margin: '1rem 0',
	},
}));

const EndOfLifePage = ({ data, location }) => {
	const end = data.end.edges[0].node;
	const classes = useStyles();

	const { formModalOpen } = useContext(FormModalContext);

	const { hero, _rawPageContent, metaTitle, metaDescription } = end;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Container style={{ marginBottom: '4rem' }}>
				<PortableText
					content={_rawPageContent}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								className={classes.header}
								color='primary'>
								{children}
							</Typography>
						),
						normal: ({ children }) => (
							<Typography variant='body1' className={classes.text}>
								{children}
							</Typography>
						),
						li: ({ children }) => (
							<Grid item>
								<ListItem>
									<li>{children}</li>
								</ListItem>
							</Grid>
						),

						emailPhoneLink: (props) => {
							return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
						},
					}}
				/>
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div style={{ marginTop: '12rem' }}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query EndOfLifePageQuery {
		end: allSanityEndOfLife {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					_rawPageContent
				}
			}
		}
	}
`;

export default EndOfLifePage;
